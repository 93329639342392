import React from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {getSquadAvatarUrl} from "data/utils/avatar";
import {IPreviousMeetingsController} from "./previous_meetings.controller";
import {ILastMeetings} from "data/stores/tournament_stats/tournament_stats.store";

const Wrapper = styled.div`
	width: 100%;
	padding: 12px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.statsModal.statsItemBg};
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
	white-space: nowrap;
	font-size: 11px;
	font-weight: 500;
	line-height: 12px;
	gap: 12px;
`;

const Logo = styled.img`
	width: 40px;
	height: 40px;
`;

const Scores = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	line-height: 10px;
	font-weight: 400;

	.bold {
		font-weight: 700;
	}
`;

interface IPreviousMeetings {
	homeSquadId: number;
	awaySquadId: number;
	list: ILastMeetings[];
}

export const PreviousMeetings: React.FC<IPreviousMeetings> = observer(
	({list, awaySquadId, homeSquadId}) => {
		const {i18n, homeSquad, awaySquad, getScoreCN} =
			useViewController<IPreviousMeetingsController>(Bindings.PreviousMeetingsController, {
				homeSquadId,
				awaySquadId,
			});

		if (!homeSquad || !awaySquad || !list.length) {
			return null;
		}

		return (
			<Wrapper>
				<Logo src={getSquadAvatarUrl(homeSquad.id)} alt={homeSquad.name} />

				<Scores>
					{list.map((match, i) => (
						<div className={getScoreCN(match, "home")} key={i}>
							{match.homeScore ?? "-"}
						</div>
					))}
				</Scores>

				<div>{i18n.t("modal.game_stats.previous_meetings", "Previous Meetings")}</div>

				<Scores>
					{list.map((match, i) => (
						<div className={getScoreCN(match, "away")} key={i}>
							{match.awayScore ?? "-"}
						</div>
					))}
				</Scores>

				<Logo src={getSquadAvatarUrl(awaySquad.id)} alt={awaySquad.name} />
			</Wrapper>
		);
	}
);
