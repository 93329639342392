import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {tournamentIcons} from "data/constants/game";
import {getSquadAvatarUrl} from "data/utils/avatar";
import {TournamentIconType} from "data/enums";
import {ModalBase, ModalDesc} from "views/components/modals/modal_base/modal_base.component";
import {Exist} from "views/components/exist/exist.component";
import {
	Container,
	List,
	SModalTitle,
	SquadLogo,
	SquadName,
	TournamentIcon,
	Tournament,
} from "views/components/modals/modal_predictions_saved/modal_predictions_saved.component";
import {IModalStreakPredictionsSavedController} from "./modal_streak_predictions_saved.controller";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalProgress.svg";

const STournament = styled(Tournament)`
	&.skipped {
		grid-template-columns: 40px 38px 1fr 24px 1fr 38px 40px;
	}
`;

export const ModalStreakPredictionsSaved: React.FC = observer(() => {
	const {i18n, isOpen, closeHandler, tournaments, roundId} =
		useViewController<IModalStreakPredictionsSavedController>(
			Bindings.ModalStreakPredictionsSavedController
		);

	return (
		<ModalBase
			isOpen={isOpen}
			closeHandler={closeHandler}
			className="confirm-modal small-mb-pd">
			<Container>
				<ModalIcon />

				<div>
					<SModalTitle>
						{i18n.t("tips.summary.title", "Your Round {{X}} Summary", {X: roundId})}
					</SModalTitle>

					<ModalDesc>
						{i18n.t(
							"tips.summary.body",
							"You can make edits to your round picks up until the start of each game"
						)}
					</ModalDesc>
				</div>

				<List>
					{tournaments.map(
						({
							id,
							awayIconType,
							homeIconType,
							homeSquadId,
							awaySquadId,
							homeSquadName,
							awaySquadName,
							isSelectedAwaySquad,
							isSelectedHomeSquad,
							className,
						}) => {
							const HomeIcon = tournamentIcons[homeIconType];
							const AwayIcon = tournamentIcons[awayIconType];

							return (
								<STournament key={id} className={className}>
									<TournamentIcon>
										<Exist when={isSelectedHomeSquad}>
											<HomeIcon />
										</Exist>
									</TournamentIcon>

									<SquadLogo
										src={getSquadAvatarUrl(homeSquadId)}
										alt={homeSquadName}
									/>

									<SquadName>{homeSquadName}</SquadName>

									<Exist when={awayIconType === TournamentIconType.SKIPPED}>
										<AwayIcon />
									</Exist>

									<SquadName className="reverse">{awaySquadName}</SquadName>

									<SquadLogo
										src={getSquadAvatarUrl(awaySquadId)}
										alt={awaySquadName}
									/>

									<TournamentIcon>
										<Exist when={isSelectedAwaySquad}>
											<AwayIcon />
										</Exist>
									</TournamentIcon>
								</STournament>
							);
						}
					)}
				</List>
			</Container>
		</ModalBase>
	);
});
