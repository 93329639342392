import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {get, isNumber} from "lodash";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;
export const extractErrorStatus = (error: AxiosError<IApiResponse>) =>
	get(error.response, "status", 400);

export const copyToClipboard = async (content: string) => {
	try {
		await navigator.clipboard.writeText(content);
		return true;
	} catch (_err) {
		return false;
	}
};

export const isAllTrue = (array: boolean[]) => {
	let allTrue = true;
	array.forEach((value) => {
		if (!value) {
			allTrue = false;
		}
	});
	return allTrue;
};

export const isAnyTrue = (array: boolean[]) => {
	let anyTrue = false;
	array.forEach((value) => {
		if (value) {
			anyTrue = true;
		}
	});
	return anyTrue;
};

export const genders = [
	{
		value: "female",
		text: "Female",
	},
	{
		value: "male",
		text: "Male",
	},
	// {
	// 	value: "non_binary",
	// 	text: "Non Binary",
	// },
	{
		value: "other",
		text: "Other",
	},
	{
		value: "not_say",
		text: "Prefer Not to Say",
	},
];

export const getFormattedNumber = (value: number | null) => {
	return isNumber(value) ? value.toLocaleString("en-US") : "-";
};

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
export * from "data/utils/dayWithNth";
