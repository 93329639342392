import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {type IPlayersStore, PlayersStore} from "data/stores/players/players.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/components/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	CreateLeagueController,
	type ICreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {IMyTipsController, MyTipsController} from "views/pages/my_tips/my_tips.controller";
import {ISubNavController, SubNavController} from "views/components/sub_nav/sub_nav.controller";
import {
	ITippingBannerController,
	TippingBannerController,
} from "views/components/tips_banner/tipping/tipping_banner.controller";
import {
	IRoundSelectorController,
	RoundSelectorController,
} from "views/components/round_selector/round_selector.controller";
import {
	ITippingCardController,
	TippingCardController,
} from "views/components/tipping_card/tipping_card.controller";
import {
	ILoginCallbackController,
	LoginCallbackController,
} from "views/pages/login_callback/login_callback.controller";
import {type IStatesStore, StatesStore} from "data/stores/states/states.store";
import {type INrlIdApiProvider, NrlIdApiProvider} from "data/providers/api/nrl_id.api.provider";
import {
	HeaderController,
	type IHeaderController,
} from "views/components/header/header/header.controller";
import {
	BurgerNavLinkController,
	type IBurgerNavLinkController,
} from "views/components/header/burger_nav/burger_nav_link/burger_nav_link.controller";
import {
	BurgerNavController,
	type IBurgerNavController,
} from "views/components/header/burger_nav/burger_nav.controller";
import {
	type ITopActionsController,
	TopActionsController,
} from "views/pages/my_tips/top_actions/top_actions.controller";
import {type IPredictionsStore, PredictionsStore} from "data/stores/predictions/predictions.store";
import {type ITipsController, TipsController} from "views/pages/my_tips/tips/tips.controller";
import {
	type IPredictionApiProvider,
	PredictionApiProvider,
} from "data/providers/api/prediction.api.provider";
import {
	type IJoinLeaguesCardController,
	JoinLeaguesCardController,
} from "views/components/league_card/join_leagues_card.controller";
import {
	type ILeaguesSearchController,
	LeaguesSearchController,
} from "views/components/leagues_search/leagues_search.controller";
import {
	type ITipsProfileController,
	TipsProfileController,
} from "views/pages/my_tips/tips_profile/tips_profile.controller";
import {
	type IModalAutoPickController,
	ModalAutoPickController,
} from "views/components/modals/modal_auto_pick/modal_auto_pick.controller";
import {
	type IInviteLeagueCardController,
	InviteLeagueCardController,
} from "views/components/league_card/invite_league_card.controller";
import {
	type ILeagueCardController,
	LeagueCardController,
} from "views/components/league_card/league_card.controller";
import {
	type IRegenerateLeagueCardController,
	RegenerateLeagueCardController,
} from "views/components/league_card/regenerate_league_card.controller";
import {
	type ILeagueTableController,
	LeagueTableController,
} from "views/pages/league_table/league_table.controller";
import {
	type IModalPredictionsSavedController,
	ModalPredictionsSavedController,
} from "views/components/modals/modal_predictions_saved/modal_predictions_saved.controller";
import {
	type IModalCompleteRoundController,
	ModalCompleteRoundController,
} from "views/components/modals/modal_complete_round/modal_complete_round.controller";
import {
	IModalCompleteRoundStore,
	ModalCompleteRoundStore,
} from "data/stores/modals/modal_complete_round.store";
import {
	type IRankingApiProvider,
	RankingApiProvider,
} from "data/providers/api/ranking.api.provider";
import {
	type IStreakRankingApiProvider,
	StreakRankingApiProvider,
} from "data/providers/api/streak_ranking.api.provider";
import {type ILadderController, LadderController} from "views/components/ladder/ladder.controller";
import {
	type IModalShareController,
	ModalShareController,
} from "views/components/modals/modal_share/modal_share.controller";
import {
	type ITippingLayoutController,
	TippingLayoutController,
} from "views/components/layouts/tipping_layout/tipping_layout.controller";
import {
	type ILandingLayoutController,
	LandingLayoutController,
} from "views/components/layouts/landing_layout/landing_layout.controller";
import {type ISportsbetStore, SportsbetStore} from "data/stores/sportsbet/sportsbet.store";
import {
	type ISportbetLayoutController,
	SportbetLayoutController,
} from "views/components/layouts/sportbet_layout/sportbet_layout.controller";
import {
	type IOddsController,
	OddsController,
} from "views/components/modals/modal_predictions_saved/odds/odds.controller";
import {
	GameBarController,
	type IGameBarController,
} from "views/components/game_bar/game_bar.controller";
import {GameBarStore, type IGameBarStore} from "data/stores/game_bar/game_bar.store";
import {BannerStore, type IBannerStore} from "data/stores/banner/banner.store";
import {
	type ILeagueRankingsStore,
	LeagueRankingsStore,
} from "data/stores/league_rankings/league_rankings.store";
import {
	type ITippingGameController,
	TippingGameController,
} from "views/pages/landing/games/tipping_game/tipping_game.controller";
import {
	type IRankingsController,
	RankingsController,
} from "views/pages/rankings/rankings.controller";
import {type IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {
	type ITutorialController,
	TutorialController,
} from "views/components/tutorial/tutorial.controller";
import {type ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {
	type ITutorialStepController,
	TutorialStepController,
} from "views/components/tutorial/tutorial_step/tutorial_step.controller";
import {type IShareApiProvider, ShareApiProvider} from "data/providers/api/share.api.provider";
import {type IMonthsStore, MonthsStore} from "data/stores/months/months.store";
import {
	type IRankingsFiltersController,
	RankingsFiltersController,
} from "views/pages/rankings/rankings_filters/rankings_filters.controller";
import {
	type ITableRowController,
	TableRowController,
} from "views/pages/rankings/rankings_table/table_row/table_row.controller";
import {
	type ILeagueTableRowController,
	LeagueTableRowController,
} from "views/pages/league_table/table_row/league_table_row.controller";
import {
	type IRankingsTableController,
	RankingsTableController,
} from "views/pages/rankings/rankings_table/rankings_table.controller";
import {
	type IModalJoinLeagueInviteController,
	ModalJoinLeagueInviteController,
} from "views/components/modals/modal_join_league_invite/modal_join_league_invite.controller";
import {
	type IModalInviteViaEmailController,
	ModalInviteViaEmailController,
} from "views/components/modals/modal_invite_via_email/modal_invite_via_email.controller";
import {
	type IModalGameStatsController,
	ModalGameStatsController,
} from "views/components/modals/modal_game_stats/modal_game_stats.controller";
import {
	type ILeagueTableFiltersController,
	LeagueTableFiltersController,
} from "views/pages/league_table/league_table_filters/league_table_filters.controller";
import {
	GameStatsCardController,
	type IGameStatsCardController,
} from "views/components/modals/modal_game_stats/game_stats_card/game_stats_card.controller";
import {AutoPickStore, type IAutoPickStore} from "data/stores/auto_pick/auto_pick.store";
import {
	GameBannerController,
	type IGameBannerController,
} from "views/components/modals/modal_game_stats/game_stats_card/game_banner/game_banner.controller";
import {type ITournamentStore, TournamentStore} from "data/stores/tournament/tournament.store";
import {AdvertController, type IAdvertController} from "views/components/advert/advert.controller";
import {
	type ITournamentStatsStore,
	TournamentStatsStore,
} from "data/stores/tournament_stats/tournament_stats.store";
import {
	type ITournamentStatsCircleController,
	TournamentStatsCircleController,
} from "views/components/tournament_stats_circle/tornament_stats_circle.controller";
import {
	type IPreviousMeetingsController,
	PreviousMeetingsController,
} from "views/components/previous_meetings/previous_meetings.controller";
import {
	type IStreakLayoutController,
	StreakLayoutController,
} from "views/components/layouts/streak_layout/streak_layout.controller";
import {type IStreakController, StreakController} from "views/pages/streak/streak.controller";
import {
	type IStreakPredictionsStore,
	StreakPredictionsStore,
} from "data/stores/streak_predictions/streak_predictions.store";
import {
	type IStreakPredictionApiProvider,
	StreakPredictionApiProvider,
} from "data/providers/api/streak_prediction.api.provider";
import {
	type IStreakGameBarStore,
	StreakGameBarStore,
} from "data/stores/streak_game_bar/streak_game_bar.store";
import {
	type IStreakTopActionsController,
	StreakTopActionsController,
} from "views/pages/streak/streak_top_actions/streak_top_actions.controller";
import {
	type IStreakGameBarController,
	StreakGameBarController,
} from "views/components/streak_game_bar/streak_game_bar.controller";
import {
	type IStreakTipsController,
	StreakTipsController,
} from "views/pages/streak/streak_tips/streak_tips.controller";
import {
	type IStreakCardController,
	StreakCardController,
} from "views/pages/streak/streak_tips/streak_card/streak_card.controller";
import {
	type IStreakBannerController,
	StreakBannerController,
} from "views/components/tips_banner/streak/streak_banner.controller";
import {
	type IStreakProgressController,
	StreakProgressController,
} from "views/pages/streak/streak_top_actions/streak_progress/streak_progress.controller";
import {
	type IModalStreakPredictionsSavedController,
	ModalStreakPredictionsSavedController,
} from "views/components/modals/modal_streak_predictions_saved/modal_streak_predictions_saved.controller";
import {
	type IStreakRankingsStore,
	StreakRankingsStore,
} from "data/stores/streak_rankings/streak_rankings.store";
import {
	type IStreakRankingsController,
	StreakRankingsController,
} from "views/pages/streak_rankings/streak_rankings.controller";
import {
	type IStreakRankingsTableController,
	StreakRankingsTableController,
} from "views/pages/streak_rankings/streak_rankings_table/streak_rankings_table.controller";
import {
	type IStreakRankingsTableRowController,
	StreakRankingsTableRowController,
} from "views/pages/streak_rankings/streak_rankings_table/streak_rankings_table_row/streak_rankings_table_row.controller";
import {
	type IStreakGameController,
	StreakGameController,
} from "views/pages/landing/games/streak_game/streak_game.controller";
import {
	type IFormGuideController,
	FormGuideController,
} from "views/components/form_guide/form_guide.controller";
import {
	type IKeyChangesController,
	KeyChangesController,
} from "views/components/modals/modal_game_stats/game_stats_card/key_changes/key_changes.controller";
import {
	type ITeamStatsController,
	TeamStatsController,
} from "views/components/modals/modal_game_stats/game_stats_card/team_stats/team_stats.controller";
import {
	type INRLWTippingGameController,
	NRLWTippingGameController,
} from "views/pages/landing/games/nrlw_tipping_game/nrlw_tipping_game.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<IPredictionApiProvider>(Bindings.PredictionApiProvider).to(PredictionApiProvider);
	bind<IStreakPredictionApiProvider>(Bindings.StreakPredictionApiProvider).to(
		StreakPredictionApiProvider
	);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<INrlIdApiProvider>(Bindings.NrlIdApiProvider).to(NrlIdApiProvider);
	bind<IRankingApiProvider>(Bindings.RankingApiProvider).to(RankingApiProvider);
	bind<IStreakRankingApiProvider>(Bindings.StreakRankingApiProvider).to(StreakRankingApiProvider);
	bind<IShareApiProvider>(Bindings.ShareApiProvider).to(ShareApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IStatesStore>(Bindings.StatesStore).to(StatesStore).inSingletonScope();
	bind<IPredictionsStore>(Bindings.PredictionsStore).to(PredictionsStore).inSingletonScope();
	bind<IModalCompleteRoundStore>(Bindings.ModalCompleteRoundStore)
		.to(ModalCompleteRoundStore)
		.inSingletonScope();
	bind<ISportsbetStore>(Bindings.SportsbetStore).to(SportsbetStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
	bind<IBannerStore>(Bindings.BannerStore).to(BannerStore).inSingletonScope();
	bind<ILeagueRankingsStore>(Bindings.LeagueRankingsStore)
		.to(LeagueRankingsStore)
		.inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<IMonthsStore>(Bindings.MonthsStore).to(MonthsStore).inSingletonScope();
	bind<IAutoPickStore>(Bindings.AutoPickStore).to(AutoPickStore).inSingletonScope();
	bind<ITournamentStatsStore>(Bindings.TournamentStatsStore)
		.to(TournamentStatsStore)
		.inSingletonScope();
	bind<ITournamentStore>(Bindings.TournamentStore).to(TournamentStore).inSingletonScope();
	bind<IStreakPredictionsStore>(Bindings.StreakPredictionsStore)
		.to(StreakPredictionsStore)
		.inSingletonScope();
	bind<IStreakGameBarStore>(Bindings.StreakGameBarStore)
		.to(StreakGameBarStore)
		.inSingletonScope();
	bind<IStreakRankingsStore>(Bindings.StreakRankingsStore)
		.to(StreakRankingsStore)
		.inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILandingController>(Bindings.LoginController).to(LandingController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IMyTipsController>(Bindings.MyTipsController).to(MyTipsController);
	bind<ISubNavController>(Bindings.SubNavController).to(SubNavController);
	bind<ITippingBannerController>(Bindings.TippingBannerController).to(TippingBannerController);
	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<ITippingCardController>(Bindings.TippingCardController).to(TippingCardController);
	bind<ILoginCallbackController>(Bindings.LoginCallbackController).to(LoginCallbackController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IBurgerNavLinkController>(Bindings.BurgerNavLinkController).to(BurgerNavLinkController);
	bind<IBurgerNavController>(Bindings.BurgerNavController).to(BurgerNavController);
	bind<ITopActionsController>(Bindings.TopActionsController).to(TopActionsController);
	bind<ITipsController>(Bindings.TipsController).to(TipsController);
	bind<IJoinLeaguesCardController>(Bindings.JoinLeaguesCardController).to(
		JoinLeaguesCardController
	);
	bind<ILeaguesSearchController>(Bindings.LeaguesSearchController).to(LeaguesSearchController);
	bind<ITipsProfileController>(Bindings.TipsProfileController).to(TipsProfileController);
	bind<IModalAutoPickController>(Bindings.ModalAutoPickController).to(ModalAutoPickController);
	bind<IInviteLeagueCardController>(Bindings.InviteLeagueCardController).to(
		InviteLeagueCardController
	);
	bind<ILeagueCardController>(Bindings.LeagueCardController).to(LeagueCardController);
	bind<IRegenerateLeagueCardController>(Bindings.RegenerateLeagueCardController).to(
		RegenerateLeagueCardController
	);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
	bind<IModalPredictionsSavedController>(Bindings.ModalPredictionsSavedController).to(
		ModalPredictionsSavedController
	);
	bind<IModalCompleteRoundController>(Bindings.ModalCompleteRoundController).to(
		ModalCompleteRoundController
	);
	bind<ILadderController>(Bindings.LadderController).to(LadderController);
	bind<IModalShareController>(Bindings.ModalShareController).to(ModalShareController);
	bind<ITippingLayoutController>(Bindings.TippingLayoutController).to(TippingLayoutController);
	bind<ILandingLayoutController>(Bindings.LandingLayoutController).to(LandingLayoutController);
	bind<ISportbetLayoutController>(Bindings.SportbetLayoutController).to(SportbetLayoutController);
	bind<IOddsController>(Bindings.OddsController).to(OddsController);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<ITippingGameController>(Bindings.TippingGameController).to(TippingGameController);
	bind<IRankingsController>(Bindings.RankingsController).to(RankingsController);
	bind<ITutorialController>(Bindings.TutorialController).to(TutorialController);
	bind<ITutorialStepController>(Bindings.TutorialStepController).to(TutorialStepController);
	bind<IRankingsFiltersController>(Bindings.RankingsFiltersController).to(
		RankingsFiltersController
	);
	bind<ITableRowController>(Bindings.TableRowController).to(TableRowController);
	bind<IRankingsTableController>(Bindings.RankingsTableController).to(RankingsTableController);
	bind<IModalJoinLeagueInviteController>(Bindings.ModalJoinLeagueInviteController).to(
		ModalJoinLeagueInviteController
	);
	bind<IModalInviteViaEmailController>(Bindings.ModalInviteViaEmailController).to(
		ModalInviteViaEmailController
	);
	bind<IModalGameStatsController>(Bindings.ModalGameStatsController).to(ModalGameStatsController);
	bind<IAdvertController>(Bindings.AdvertController).to(AdvertController);
	bind<ILeagueTableRowController>(Bindings.LeagueTableRowController).to(LeagueTableRowController);
	bind<ILeagueTableFiltersController>(Bindings.LeagueTableFiltersController).to(
		LeagueTableFiltersController
	);
	bind<IGameStatsCardController>(Bindings.GameStatsCardController).to(GameStatsCardController);
	bind<IGameBannerController>(Bindings.GameBannerController).to(GameBannerController);
	bind<IPreviousMeetingsController>(Bindings.PreviousMeetingsController).to(
		PreviousMeetingsController
	);
	bind<ITournamentStatsCircleController>(Bindings.TournamentStatsCircleController).to(
		TournamentStatsCircleController
	);
	bind<IStreakLayoutController>(Bindings.StreakLayoutController).to(StreakLayoutController);
	bind<IStreakController>(Bindings.StreakController).to(StreakController);
	bind<IStreakTopActionsController>(Bindings.StreakTopActionsController).to(
		StreakTopActionsController
	);
	bind<IStreakGameBarController>(Bindings.StreakGameBarController).to(StreakGameBarController);
	bind<IStreakTipsController>(Bindings.StreakTipsController).to(StreakTipsController);
	bind<IStreakCardController>(Bindings.StreakCardController).to(StreakCardController);
	bind<IStreakBannerController>(Bindings.StreakBannerController).to(StreakBannerController);
	bind<IStreakProgressController>(Bindings.StreakProgressController).to(StreakProgressController);
	bind<IModalStreakPredictionsSavedController>(Bindings.ModalStreakPredictionsSavedController).to(
		ModalStreakPredictionsSavedController
	);
	bind<IStreakRankingsController>(Bindings.StreakRankingsController).to(StreakRankingsController);
	bind<IStreakRankingsTableController>(Bindings.StreakRankingsTableController).to(
		StreakRankingsTableController
	);
	bind<IStreakRankingsTableRowController>(Bindings.StreakRankingsTableRowController).to(
		StreakRankingsTableRowController
	);
	bind<IStreakGameController>(Bindings.StreakGameController).to(StreakGameController);
	bind<INRLWTippingGameController>(Bindings.NRLWTippingGameController).to(
		NRLWTippingGameController
	);
	bind<IFormGuideController>(Bindings.FormGuideController).to(FormGuideController);
	bind<IKeyChangesController>(Bindings.KeyChangesController).to(KeyChangesController);
	bind<ITeamStatsController>(Bindings.TeamStatsController).to(TeamStatsController);
});
