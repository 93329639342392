import "reflect-metadata";

import "assets/css/reset.css";
import "assets/fonts/stylesheet.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {BrowserTracing, init, Integrations, Replay} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {ModalAutoPick} from "views/components/modals/modal_auto_pick/modal_auto_pick.component";
import {ThemeProvider} from "@mui/material";
import {theme} from "./assets/theming/theme";
import {ModalPredictionsSaved} from "views/components/modals/modal_predictions_saved/modal_predictions_saved.component";
import {ModalCompleteRound} from "views/components/modals/modal_complete_round/modal_complete_round.component";
import {ModalShare} from "views/components/modals/modal_share/modal_share.component";
import {ModalJoinLeagueInvite} from "views/components/modals/modal_join_league_invite/modal_join_league_invite.component";
import {SecretGate} from "views/components/secret_gate/secret_gate.component";
import {ModalInviteViaEmail} from "views/components/modals/modal_invite_via_email/modal_invite_via_email.component";
import {ModalGameStats} from "views/components/modals/modal_game_stats/modal_game_stats.component";
import {ModalStreakPredictionsSaved} from "views/components/modals/modal_streak_predictions_saved/modal_streak_predictions_saved.component";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "tipping.nrl.com"],
		ignoreErrors: [
			"Abort due to cancellation of share",
			"AbortError: Share canceled",
			"Can't find variable: _AutofillCallbackHandler",
			"window.googletag.pubads is not a function",
			"Non-Error promise rejection captured with value",
			/Load failed/i,
			/Network Error/i,
			/Fetch Error/i,
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<InjectionProvider container={DIContainer}>
				<Bootstrap>
					<BrowserRouter basename={BASE_URL}>
						<SecretGate>
							<Suspense fallback={<PagePreloader />}>
								{SecretGateController.IS_SECRET_PASSED ? (
									<Session>
										<RootRoutes />
									</Session>
								) : (
									<SecretGateRoutes />
								)}
							</Suspense>
							<ModalError />
							<ModalConfirm />
							<ModalAutoPick />
							<ModalPredictionsSaved />
							<ModalCompleteRound />
							<ModalShare />
							<ModalJoinLeagueInvite />
							<ModalInviteViaEmail />
							<ModalGameStats />
							<ModalStreakPredictionsSaved />
						</SecretGate>
					</BrowserRouter>
				</Bootstrap>
			</InjectionProvider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
